import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App.vue'
import router from './router'
// import axios from 'axios'
import 'amfe-flexible'// 移动端适配
import ElementUI from 'element-ui'//引入element-ui库
import 'element-ui/lib/theme-chalk/index.css'  //引入element-ui样式
import VFormRender from 'vform-builds/dist/VFormRender.umd.min.js'
import 'vform-builds/dist/VFormRender.css'  //引入VFormRender样式
import commonWay from './assets/js/common.js'//引入公共的方法

Vue.use(Vant);
Vue.use(ElementUI)  //全局注册element-ui
Vue.use(VFormRender)  //全局注册VFormRender等组件
Vue.use(commonWay);
Vue.config.productionTip = false
// 
// Vue.prototype.$axios = axios
// 
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '调查问卷';
  }
  next();
  // 
  const token = localStorage.getItem('token')
  const managerFlag = localStorage.getItem('managerFlag')
  const deptId = localStorage.getItem('deptId')
  if (to.meta.requiresAuth && managerFlag === "ordinary") {
    next("mylist")
  } else {
    next()
  }
});

// 
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
