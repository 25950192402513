import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index'),
    meta: { title: '信息类型' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp/SignUp.vue'),
    meta: { title: '注册账号' }
  }, {
    path: '/reset-password',
    name: 'reset',
    component: () => import('../views/ResetPassword/ResetPassword.vue'),
    meta: { title: '重置密码' }
  },
  {
    path: '/survey-item',
    name: 'SurveyItem',
    component: () => import('../views/SurveyItem/SurveyItem.vue'),
    meta: { title: '问卷列表' }
  },
  {
    path: '/answer-info',
    name: 'AnswerInfo',
    component: () => import('../views/AnswerInfo/AnswerInfo.vue'),
    meta: { title: '问卷调查' }
  },

  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/news.vue'),
    meta: { title: '消息通知' }
  },
  {
    path: '/super-admin',
    name: 'manage',
    component: () => import('../views/SuperAdmin/SuperAdmin.vue'),
    meta: { title: '数据统计', requiresAuth: true }

  }, {
    path: '/user-ranking',
    name: 'UserRanking',
    component: () => import('../views/UserRanking/UserRanking.vue'),
    meta: { title: '积分排名' }
  },
  {
    path: '/message-reply',
    name: 'MessageReply',
    component: () => import('../views/MessageReply/MessageReply.vue'),
    meta: { title: '意见回复' }
  },
  {
    path: '/my-list',
    name: 'MyList',
    component: () => import('../views/MyList/MyList.vue'),
    meta: { title: '我的问卷' }
  },
  {
    path: '/my-save',
    name: 'MySave',
    component: () => import('../views/MySave/MySave.vue'),
    meta: { title: '我的保存' }
  },
  {
    path: '/save-list',
    name: 'SaveList',
    component: () => import('../views/SaveList/SaveList.vue'),
    meta: { title: '问题列表' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("token")
//   if (token || to.path === ("/")) {
//     next()
//   } else {
//     next("/")
//   }
// })

export default router
